$(document).ready(function() {
    // Додати клас is-active до першої кнопки
    $('.services-list__button').first().addClass('is-active');

    // Отримати всі кнопки зі списку послуг
    $('.services-list__button').on('click', function(e) {
        e.preventDefault(); // Запобігає стандартній поведінці посилання

        // Видалити клас is-active з усіх кнопок
        $('.services-list__button').removeClass('is-active');

        // Додати клас is-active до натиснутої кнопки
        $(this).addClass('is-active');

        // Отримати текст кнопки
        const buttonText = $(this).text().trim();

        // Знайти відповідний елемент серед price-courant__item
        const $targetItem = $('.price-courant__item').filter(function() {
            return $(this).find('h2').text().trim() === buttonText;
        });

        // Якщо знайдено відповідний елемент, прокрутити до нього
        if ($targetItem.length) {
            $('html, body').animate({
                scrollTop: $targetItem.offset().top
            }, 500); // Зменшено до 500 мс
        }
    });
});
