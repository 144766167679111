import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const swiperBlog = new Swiper(".blog-swiper", {
    slidesPerView: 1.3,
    spaceBetween: 11,
    breakpoints: {
        768: {
            spaceBetween: 20,
            slidesPerView: 2,
        },
        992: {
            spaceBetween: 20,
            slidesPerView: 3,
            navigation: {
                prevEl: ".blog-wrapper .prev",
                nextEl: ".blog-wrapper .next",
            },
        },
    },
    navigation: {
        prevEl: "#blog .prev",
        nextEl: "#blog .next",
    },
    autoHeight: true,
});

document.querySelectorAll('.rooms-swiper').forEach((swiperEl) => {
    const index = swiperEl.getAttribute('data-index');
    new Swiper(swiperEl, {
        slidesPerView: 1,
        navigation: {
            prevEl: `.rooms-list__item .prev[data-index="${index}"]`,
            nextEl: `.rooms-list__item .next[data-index="${index}"]`,
        },
        loop: true,
    });
});


const residenceSwiper = new Swiper(".rooms-wrapper-swiper", {
    slidesPerView: 1,
    spaceBetween: 11,
    navigation: {
        prevEl: ".rooms-wrapper-swiper .prev",
        nextEl: ".rooms-wrapper-swiper .next",
    },
    autoHeight: true,
    breakpoints: {

        992: {
            spaceBetween: 0,
            navigation: {
                prevEl: ".rooms-wrapper__content .prev",
                nextEl: ".rooms-wrapper__content .next",
            },
        },
    },
});


const mainServicesSwiper = new Swiper(".main-swiper-services", {
    slidesPerView: 1.27,
    spaceBetween: 11,
    navigation: {
        prevEl: ".main-swiper-services .prev",
        nextEl: ".main-swiper-services .next",
    },
    breakpoints: {

        992: {
            spaceBetween: 20,
            slidesPerView: 3,
            navigation: {
                prevEl: ".desk-ctrl .prev",
                nextEl: ".desk-ctrl .next",
            },
        },
    },
});
const singleGallerySwiper = new Swiper(".single-gallery-swiper", {
    slidesPerView: 1.27,
    spaceBetween: 11,
    navigation: {
        prevEl: ".single-gallery-swiper .prev",
        nextEl: ".single-gallery-swiper .next",
    },
    breakpoints: {

        992: {
            spaceBetween: 21,
            slidesPerView: 3,
            navigation: {
                prevEl: ".single-about--controller .prev",
                nextEl: ".single-about--controller .next",
            },
        },
    },
});
