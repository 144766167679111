const menuButtons = document.querySelectorAll('.menu-item');
const menu = document.getElementById('mob-menu');

function closeMenu() {
    if (menu) {
        menu.classList.add('is-hidden');
        document.body.classList.remove('body-no-scroll');
    }
}

// Додаємо обробник подій для кнопок меню
menuButtons.forEach(button => {
    button.addEventListener('click', () => {
        closeMenu();
    });
});
