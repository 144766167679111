const menuButtons = document.querySelectorAll('.menu-button');
const closeButton = document.querySelector('.button-close');
const modalButton = document.querySelector('.modal-button');
const menu = document.getElementById('mob-menu');
const appButtons = document.querySelectorAll('.app-button-js');
const appointmentPopup = document.querySelector('#appointment');

function openPopup() {
    if (appointmentPopup) {
        appointmentPopup.classList.remove('is-hidden');
        document.body.classList.add('body-no-scroll');
    }
}

function closePopup() {
    if (appointmentPopup) {
        appointmentPopup.classList.add('is-hidden');
        document.body.classList.remove('body-no-scroll');
    }
}

// Додаємо обробники подій для кожної кнопки .app-button-js
appButtons.forEach(button => {
    button.addEventListener('click', openPopup);
});

// Функція для закриття меню
function closeMenu() {
    if (menu) {
        menu.classList.add('is-hidden');
        document.body.classList.remove('body-no-scroll');
    }
}

// Функція для відкриття меню
function openMenu() {
    if (menu) {
        menu.classList.remove('is-hidden');
        document.body.classList.add('body-no-scroll');
    }
}

menuButtons.forEach(button => {
    button.addEventListener('click', () => {
        if (menu && menu.classList.contains('is-hidden')) {
            openMenu();
        } else {
            closeMenu();
        }
    });
});

if (closeButton) {
    closeButton.addEventListener('click', closeMenu);
}

if (modalButton) {
    modalButton.addEventListener('click', closePopup);
}

if (menu) {
    menu.addEventListener('click', (event) => {
        if (event.target === menu) {
            closeMenu();
        }
    });
}

if (appointmentPopup) {
    appointmentPopup.addEventListener('click', (event) => {
        if (event.target === appointmentPopup) {
            closePopup();
        }
    });
}

document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
        closeMenu();
        closePopup();
    }
});

window.addEventListener('resize', () => {
    if (window.innerWidth > 768) {
        closeMenu();
    }
});

document.addEventListener("wpcf7mailsent", () => {
    const thankYouMessages = document.querySelectorAll('.ty-message');
    thankYouMessages.forEach(message => {
        message.style.opacity = '1';
    });
});
